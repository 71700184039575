import { motion } from 'framer-motion';
import { styles } from '../styles';
import { slideIn, textVariant, zoomIn } from '../lib/motion';
import { DownloadButton } from '../ui/download-button';
import { profile } from '../assets/assets';

const About = () => {
  return (
    <div id="about" className="flex flex-col sm:flex-row py-16 sm:py-14 bg-about bg-center bg-cover h-full md:h-screen rounded-bl-[18em] w-full about-border"  >
      
        <motion.div 
            initial="hidden"
            whileInView="show" 
            viewport={{ once: true }}
            variants={zoomIn(0.1,0.7)}
            className="flex justify-center items-center flex-1 md:w-[100%] w-[60%]">
                <img src={profile} alt="profile" className='ml-12 sm:ml-0 w-[18em] h-[15em] sm:w-[28em] sm:h-[28em]'/>
        </motion.div>

        <div className='ml-14 mr-4 sm:mx-5 mt-4 sm:mt-0 flex-1 flex-wrap sm:w-full'>
            <motion.div 
                initial="hidden"
                whileInView="show" 
                viewport={{ once: true }}
                variants={textVariant(0.2)}>
                <p className={styles.sectionSubTextLight}>
                    About
                </p>
            </motion.div>
            
            <motion.div  
                initial="hidden"
                whileInView="show" 
                viewport={{ once: true }}
                variants={textVariant(0.2)}>
                <p className={styles.sectionHeadText}>
                    my story
                </p>
            </motion.div>

            <motion.div
                initial="hidden"
                whileInView="show" 
                viewport={{ once: true }}
                variants={slideIn('right', 'spring', 0.1, 0.9)}
                className="mt-2 text-butterCream text-[1em] sm:text-[1.1em] max-w-2xl ">
                I’m an enthusiastic Engineer with over <strong>5 years of experience </strong>
                in crafting seamless, intuitive, and engaging user interfaces. <br/><br/> 
                I build <strong>robust and scalable solutions</strong> by harnessing cutting-edge web technologies and frameworks, responsive design principles, UI design systems, UX best practices, and Agile methodologies.<br/><br/> 
                My journey between design and engineering has always been driven by my love for turning imagination into reality. <br/><br/>
                When I’m not at the keyboard, you'll likely find me exploring new destinations, hitting the gym, cooking, or enjoying a good book.
            </motion.div>

            {/* Resume Button (Mobile) */}
            <div className='flex flex-col w-full mt-10 justify-end items-end sm:hidden'>
                <a href='Shrushti_Mane_Resume.pdf' target="_blank" rel="noreferrer"> 
                    <DownloadButton text="Curious? Check My Resume !" icon={"file"}/>
                </a>
            </div>

            {/* Resume Button (Desktop) */}
            <div className='hidden sm:flex flex-col justify-center w-full mt-10 ml-0'>
                <a href='Shrushti_Mane_Resume.pdf' target="_blank" rel="noreferrer"> 
                    <DownloadButton text="Curious? Take a look at My Resume !" icon={"file"}/>
                </a>
            </div>
        </div>

    </div>
  );
};

export default About;
