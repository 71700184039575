import { motion } from 'framer-motion';
import { styles } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { FlipWords } from '../ui/flip-words';
import { flipWords } from '../model/constants';
import SplineModel from '../ui/spline-model';
import { email,  linkedin, resume } from '../assets/assets';

const Main = () => {
  const mailID = 'mailto:shrushti.mane01@gmail.com';
  const openSite = (site: string) => {
    switch(site){
      case "linkedin":
        window?.open("https://www.linkedin.com/in/shrushtimane/", "_blank")?.focus();
      break;
      case "github":
        window?.open("https://github.com/ManeShrushti", "_blank")?.focus();
      break;
      case "email":
        window.location.href = mailID
      break;
    }
  };
  return (
    <>
      <section id="home" className="relative flex flex-col sm:flex-row w-full h-[100vh] mt-12 sm:my-0 bg-mainCover bg-center bg-cover z-1">
      
      {/* Left Section */}
      <div className="flex flex-row sm:items-center w-full sm:w-full px-4 sm:px-4 lg:px-8 mt-6 md:mt-0">
          
          {/* Icons Column */}
          <div className="flex flex-col gap-8 z-50 items-center mt-8">
              <img
                  alt="email"
                  src={email}
                  className="cursor-pointer hover:scale-105 w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                  onClick={() => openSite("email")}
              />
              <img
                  alt="linkedin"
                  src={linkedin}
                  className="cursor-pointer hover:scale-105 w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                  onClick={() => openSite("linkedin")}
              />
              <a href='Shrushti_Mane_Resume.pdf' target="_blank"  rel="noreferrer"> 
                  <img
                      alt="resume"
                      src={resume}
                      className="cursor-pointer hover:scale-105 w-8 h-8 sm:w-10 sm:h-10 rounded-full"
                  />
              </a>
          </div>

          {/* Details Section */}
          <div className="flex flex-col ml-6 mt-4 sm:mt-4">
              <span className={`${styles.heroHeadText} text-flashWhite font-poppins`}>
                  Hi, I'm{' '}   
              </span>
              <span className="text-primary uppercase text-3xl sm:text-4xl md:text-5xl xl:text-6xl font-wadoend">
                  Shrushti
              </span>
              <div className={`${styles.heroSubText} mt-1 text-flashWhite`}>
                  I blend art and engineering into <br /> 
                  <FlipWords words={flipWords} /> web experiences, <br />
                  one pixel at a time. <br /> 
              </div>

              {/* Scroll Indicator */}
              <a href="#about" className="flex items-center mt-8 z-10">
                  <div className="w-[30px] h-[64px] rounded-3xl border-4 border-butterCream flex justify-center items-start p-0 sm:p-2">
                      <motion.div
                          animate={{ y: [0, 24, 0] }}
                          transition={{ duration: 1.5, repeat: Infinity, repeatType: 'loop' }}
                          className="mb-1"
                      >
                          <FontAwesomeIcon icon={faAnglesDown} />
                      </motion.div>
                  </div>
                  <div className="ml-4 text-butterCream font-medium">
                      Know More
                  </div>
              </a>
          </div>
      </div>

      {/* Right Section with 3D Model */}
      <div className="absolute sm:absolute flex w-full h-full z-1">
          <div className="w-full h-full mt-10">
                    {/* Model for small screens */}
            <div className="w-full h-full mt-28 sm:hidden">
                <SplineModel screenType="small"/>
            </div>

            {/* Model for larger screens */}
            <div className="hidden sm:flex w-full h-full mt-8 ml-12 pl-[12rem]">
                <SplineModel screenType="large"/>
            </div>

          </div>
      </div>
      </section>


    </>
  );
};

export default Main;
